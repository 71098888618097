import { definitions } from "../../types/supabase";
import { ResourceConsumerWithResource } from "../pages/learningLibrary/learningLibraryTypes";
import { getEnvVarOrRaise } from "./envVars";

export const logoCC =
  "https://cc-prd-retool.s3.amazonaws.com/piaf/resources/f95cd36d-aa33-4f27-b4a5-754cda787cd3/piaf-logo-light-small.png";
export const logoPiaf =
  "https://cc-prd-retool.s3.amazonaws.com/piaf/resources/f95cd36d-aa33-4f27-b4a5-754cda787cd3/piaf-logo-light-small.png";
export const logoNMA =
  "https://cc-prd-retool.s3.amazonaws.com/nma/resources/09e3f4c4-c001-4118-a96b-151ae62ed118/NMAlogo-01.png";
export const logoTP =
  "https://cc-prd-retool.s3.amazonaws.com/tp/resources/f02205f3-94f8-40c8-9b9e-5664880e402a/the_prepared_logo.png";
export const logoPS =
  "https://cc-prd-retool.s3.amazonaws.com/ps/resources/5a8a9a55-5aa6-41fd-9031-a87327d90dc6/logo-og.png";
export const logoDA =
  "https://cc-prd-retool.s3.amazonaws.com/da/resources/5b5331e0-3bbb-447c-a032-9f08daf79d44/da-fullcolor.png";

// REPLACE
export const logoBTB = logoCC;

export const faviconCC =
  "https://cc-prd-retool.s3.amazonaws.com/piaf/resources/4797bcbe-0577-4af2-9347-f60cf2fc17d1/piaf-favicon-V2.png";
export const faviconPiaf =
  "https://cc-prd-retool.s3.amazonaws.com/piaf/resources/4797bcbe-0577-4af2-9347-f60cf2fc17d1/piaf-favicon-V2.png";
export const faviconNMA =
  "https://cc-prd-retool.s3.amazonaws.com/nma/resources/80f17621-76eb-402a-9c48-148f634521e6/NMA-favicon.png";
export const faviconTP =
  "https://cc-prd-retool.s3.amazonaws.com/tp/resources/584d171a-2a30-45cf-a10a-10be795d2983/the-prepared-logo.png";
export const faviconPS =
  "https://cc-prd-retool.s3.amazonaws.com/ps/resources/29a05c8e-5fa9-4631-b4be-d7873634056b/pie-favicon.png";
export const faviconDA =
  "https://cc-prd-retool.s3.amazonaws.com/da/resources/8cacf6bf-e93d-42ee-ad0d-60605b9e6d46/favicon-32x32.png";

// REPLACE
export const faviconBTB = faviconCC;

const environment = getEnvVarOrRaise("ENV");

class BrandConfig {
  brandId: string | undefined;
  brandName: string | undefined;
  brandDescription: string | undefined;
  brandConfig: any;
  brandAbbreviation: string | undefined;
  brandResources: Array<ResourceConsumerWithResource>;

  getBrandAbbreviationForCurrentURL = () => {
    const { host } = location;

    if (host.startsWith("mop") || host.includes("masteroilpainting")) {
      return "mop";
    } else if (host.startsWith("nma") || host.includes("nancymedina")) {
      return "nma";
    } else if (host.startsWith("piaf") || host.includes("pianoinaflash")) {
      return "piaf";
    } else if (host.startsWith("tp") || host.includes("theprepared")) {
      return "tp";
    } else if (host.startsWith("ps") || host.includes("thepiesavvy")) {
      return "ps";
    } else if (host.startsWith("da") || host.includes("drumambition")) {
      return "da";
    } else if (host.startsWith("btb") || host.includes("breakthroughbanjo")) {
      return "btb";
    } else if (host.startsWith("test") || host.includes("test")) {
      return "test";
    } else {
      console.warn("Unknown brand!");
    }
  };

  constructor() {
    this.brandAbbreviation = this.getBrandAbbreviationForCurrentURL();
    this.brandResources = [];
  }

  // for App.js
  getFetchBrandOptions() {
    return ["nma", "piaf", "tp", "test", "ps", "da", "btb"];
  }

  // for loginForm + dashboard
  getBrandList() {
    return [
      {
        logo: logoPiaf,
        favicon: faviconPiaf,
        label: "piaf",
        height: "75",
        styling: "py-6",
      },
      { logo: logoTP, favicon: faviconTP, label: "tp" },
      { logo: logoNMA, favicon: faviconNMA, label: "nma" },
      { logo: logoPS, favicon: faviconPS, label: "ps" },
      { logo: logoDA, favicon: faviconDA, label: "da" },
      { logo: logoBTB, favicon: faviconBTB, label: "btb" },
    ];
  }

  setBrandConfig(
    brandId: string,
    brandName: string,
    brandDescription: string,
    brandAbbreviation: string,
    brandConfig: any,
    brandResources: Array<ResourceConsumerWithResource>
  ) {
    this.brandId = brandId;
    this.brandName = brandName;
    this.brandDescription = brandDescription;
    this.brandConfig = brandConfig || {};
    this.brandAbbreviation = brandAbbreviation;
    this.brandResources = brandResources;
  }

  getCustomColors() {
    return {
      primary: this.brandConfig?.primary_rgb_color || "0 0 0",
      secondary: this.brandConfig?.secondary_rgb_color || "0 0 0",
      tertiary: this.brandConfig?.tertiary_rgb_color || "0 0 0",
      quaternary: this.brandConfig?.quaternary_rgb_color || "0 0 0",
    };
  }

  zendeskKey() {
    return this.brandConfig?.zendesk_key || "";
  }

  navbarImage() {
    return (
      this.getBrandList().find((b) => b.label == this.brandAbbreviation)
        ?.logo || logoCC
    );
  }

  favicon() {
    return (
      this.getBrandList().find((b) => b.label == this.brandAbbreviation)
        ?.favicon || logoCC
    );
  }

  brandType() {
    switch (this.brandAbbreviation) {
      case "nma":
        return "Painting";
      case "piaf":
        return "Piano";
      case "tp":
        return "Teaching";
      case "ps":
        return "Baking";
      case "da":
        return "Drumming";
      case "btb":
        // Validate with support/marketing
        return "Strumming";
      default:
        return "";
    }
  }

  getWelcomeVideoForUserProfile(profile: definitions["profiles"]) {
    if (profile.original_brand_identifier) {
      const transitionWelcomeVideo = this.brandResources.find((resource) => {
        return (
          resource.display_type == "welcome_video_transition_from_old_system"
        );
      });

      if (transitionWelcomeVideo) {
        return transitionWelcomeVideo;
      }
    }

    return this.brandResources.find((resource) => {
      return resource.display_type == "welcome_video";
    });
  }

  brandFullName() {
    return this.brandName;
  }

  brandHomepageLink(label?: string) {
    const brand = label || this.brandAbbreviation;
    switch (brand) {
      case "nma":
        return "https://www.nancymedina.com/joyful";
      case "piaf":
        return "https://www.pianoinaflash.com/";
      case "tp":
        return "https://www.theprepared.com/";
      case "ps":
        return "https://www.thepiesavvy.com/";
      case "da":
        return "https://www.drumambition.com/";
      case "btb":
        return "https://www.breakthroughbanjo.com/";
      default:
        return "https://www.pianoinaflash.com/";
    }
  }

  brandServerUrl() {
    if (environment === "local") {
      return `http://localhost:3001/marketing/${this.brandAbbreviation}`;
    }

    switch (this.brandAbbreviation) {
      case "nma":
        return "https://cc-api.nancymedina.com";
      case "piaf":
        return "https://cc-api.pianoinaflash.com";
      case "tp":
        return "https://cc-api.theprepared.com";
      case "ps":
        return "https://cc-api.thepiesavvy.com";
      case "da":
        return "https://cc-api.drumambition.com";
      case "btb":
        return "https://cc-api.breakthroughbanjo.com";
      default:
        return "https://prd-xms-api.combinedcuriosity.com";
    }
  }

  introCompletionOfferLink() {
    return this.brandConfig?.intro_completion_offer_link || "";
  }
}

const brandConfig = new BrandConfig();

export { brandConfig };
