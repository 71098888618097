import { api, supabase } from "../api";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { brandConfig } from "../helpers/brandConfig";
import Button from "../components/buttons/Button";
import { logoCC } from "../helpers/brandConfig";
import PasswordField from "../components/PasswordField";
import React, { useEffect, useState, useContext } from "react";
import Snackbar from "../components/snackbar/snackbar";
import { useLocation } from "react-router-dom";
import { XmsSupabase } from "../xmsSupabase";

import { UserContext } from "../contexts/UserContext";

const LoginForm = ({
  rerenderParent,
}: {
  rerenderParent: undefined | (() => void);
}) => {
  const { state }: { state: any } = useLocation();
  const { setUser } = useContext(UserContext);
  const [email, setEmail] = useState(state?.email || "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(
    state?.successMessage || ""
  );
  const [formType, setFormType] = useState("login");
  const [allBrandConfigs, setAllBrandConfigs] = useState<any>(null);

  const fieldStyle =
    "p-2 mt-2 border-b-2 border-t-0 border-r-0 border-l-0 md:border-2 rounded border-black border-opacity-50 text-sm";

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.getUser();
      if (data.user) {
        setUser(undefined);
        api.logout();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setAllBrandConfigs(await api.getBrandConfigs());
    })();
  }, []);

  function resetForm() {
    setErrorMessage("");
    setSuccessMessage("");
    setPassword("");
  }

  const login = async (e: any) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Enter a valid email and password to login");
      return;
    }

    let loggedInUser = await api.login(email, password);

    const allowLoginViaPiaf = true;
    if (loggedInUser?.error) {
      let response = { ok: false };

      // eventually this may need to check different brand sources, for now, it's just PIAF
      if (allowLoginViaPiaf && brandConfig.brandAbbreviation === "piaf") {
        response = await api.loginViaPiaf(email, password);
      }

      if (response.ok) {
        const { data, error } = await api.login(email, password);
        if (data.user == null) {
          throw Error("user not found");
        }
        setUser(data.user);
        (window as any).location = `/learning-library`;
      }

      setErrorMessage(loggedInUser.error?.message || "");
      setSuccessMessage("");
    } else {
      if (loggedInUser.data.user === null) {
        throw Error("user not found");
      }
      setUser(loggedInUser.data.user);
      XmsSupabase.resetCache();

      if (rerenderParent) {
        rerenderParent();
        return;
      }

      (window as any).location = `/learning-library`;
    }
  };

  const sendResetLink = async (e: any) => {
    e.preventDefault();
    const response = await api.sendForgotPasswordEmail(email);
    if (response.ok) {
      const responseData = await response.json();
      setFormType("login");
      resetForm();
      setSuccessMessage(responseData.message);
    } else {
      setErrorMessage(
        `Please contact ${brandConfig.brandConfig.support_email} for assistance`
      );
      setSuccessMessage("");
    }
  };

  const sendNewPassword = async (e: any) => {
    e.preventDefault();
    const response = await supabase.auth.resetPasswordForEmail(email);
    if (response.error) {
      setErrorMessage(response.error.message);
      setSuccessMessage("");
    } else {
      setFormType("login");
      resetForm();
      setSuccessMessage(
        "Password reset email sent. Please check your email for instructions."
      );
    }
  };

  const actionResultMessage = () => {
    return (
      <>
        <Snackbar
          message={successMessage}
          open={Boolean(successMessage)}
          onClose={() => setSuccessMessage("")}
        />
        <Snackbar
          message={errorMessage}
          open={Boolean(errorMessage)}
          onClose={() => setErrorMessage("")}
        />
      </>
    );
  };

  const returnToLogin = () => (
    <Button
      additionalStyling="text-left text-xs p-0"
      style="text"
      onClick={() => {
        setFormType("login");
        resetForm();
      }}
    >
      <AiOutlineArrowLeft className="-mb-0.5" /> LOGIN
    </Button>
  );

  const emailField = () => (
    <input
      id="email"
      type="email"
      placeholder="Email"
      data-testid="login-email-input"
      className={fieldStyle}
      onChange={(e) => setEmail(e.target.value)}
      value={email}
    />
  );

  const forgotFields = () => {
    return (
      <form className="flex flex-col -mx-12 md:m-auto" onSubmit={sendResetLink}>
        {returnToLogin()}
        {emailField()}
        {actionResultMessage()}
        <Button
          additionalStyling="p-2 mt-4"
          onClick={sendResetLink}
          style="primary"
          type="submit"
        >
          Send Password Reset Email
        </Button>
      </form>
    );
  };

  const recoveryFields = () => {
    return (
      <form
        className="flex flex-col -mx-12 md:m-auto"
        onSubmit={sendNewPassword}
      >
        {returnToLogin()}
        <PasswordField
          handleInfoChange={(e: any) => setPassword(e.target.value)}
          placeholder="Password"
          value={password}
          className={fieldStyle}
        />
        {actionResultMessage()}
        <Button
          additionalStyling="p-2"
          onClick={sendNewPassword}
          style="primary"
          type="submit"
        >
          Save New Password
        </Button>
      </form>
    );
  };

  const loginFields = () => {
    return (
      <form className="flex flex-col gap-y-4 -mx-12 md:m-auto" onSubmit={login}>
        {emailField()}
        <PasswordField
          handleInfoChange={(e: any) => setPassword(e.target.value)}
          keyPress={(e: any) => {
            if (e.key === "Enter") login(e);
          }}
          placeholder="Password"
          value={password}
          className={fieldStyle}
          data-testid="login-password-input"
        />

        {actionResultMessage()}
        <Button
          additionalStyling="p-2"
          onClick={login}
          style="primary"
          type="submit"
          buttonId="login-button"
          data-testid="login-button"
        >
          Login
        </Button>
        <div className="flex flex-row justify-center">
          <Button
            additionalStyling="text-xs"
            onClick={() => {
              setFormType("forgot");
              resetForm();
            }}
            style="text"
          >
            Forgot Password
          </Button>
        </div>
      </form>
    );
  };

  const displayForm = () => {
    switch (formType) {
      case "forgot":
        return forgotFields();
      case "recovery":
        return recoveryFields();
      default:
        return loginFields();
    }
  };

  if (!brandConfig.brandAbbreviation) {
    const redirect = (label: any) => {
      if (location.hostname === "localhost") {
        location.host = `${label}.lvh.me`;
      } else {
        const brandRedirects = allBrandConfigs.map((b: any) => {
          return { [b.brands.abbreviation]: b.modal_success_redirect_url };
        });
        location.host = brandRedirects[label];
      }
    };

    return (
      <div className="bg-white shadow rounded-xl p-12 pb-4 w-3/4 md:w-1/2 lg:w-2/5 2xl:w-1/4 m-auto">
        <header>
          <img
            className="flex flex-col pb-6 w-3/4 h-full m-auto cursor-pointer"
            src={logoCC}
            alt="brand logo"
            onClick={() =>
              window.open("https://www.combinedcuriosity.com/", "_blank")
            }
          />
          <p className="m-0 uppercase text-center italic -mx-8">
            The art of discovery, on demand
          </p>
          <p className="m-0 pb-4 text-center text-xs">
            Click a logo to go to its login page
          </p>
        </header>
        <article className="flex flex-row justify-around gap-x-4 w-full">
          {brandConfig.getBrandList().map((b) => (
            <img
              alt={`${b.label} logo`}
              key={`brand_link_${b.label}`}
              aria-label={`brand_link_${b.label}`}
              data-testid={`brand_link_${b.label}`}
              src={b.logo}
              className={`my-auto cursor-pointer shadow bg-white rounded-xl p-2 h-24 w-full ${b?.styling}`}
              onClick={() => redirect(b.label)}
            />
          ))}
        </article>
      </div>
    );
  }

  return (
    <nav className="md:bg-white md:shadow-md rounded-xl p-12 pb-4 w-3/4 md:w-1/2 lg:w-2/5 2xl:w-1/4 m-auto">
      <img
        className="flex flex-col pb-6 m-auto"
        alt="brand logo"
        src={brandConfig.navbarImage()}
      />
      {displayForm()}
    </nav>
  );
};

export default LoginForm;
