import React, { useEffect } from "react";
import { api, supabase } from "../api";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import uuid from "react-native-uuid";
import { XmsSupabase } from "../xmsSupabase";

export default function Enroll() {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = decodeURIComponent(searchParams.get("email") || "");
  const name = decodeURIComponent(searchParams.get("name") || "");
  const offerIds = decodeURIComponent(searchParams.get("offerIds") || "");
  const password = uuid.v4();
  const clientSideId = decodeURIComponent(
    searchParams.get("clientSideId") || ""
  );
  const transactionURL = decodeURIComponent(
    searchParams.get("transactionURL") || ""
  );

  const navigate = useNavigate();
  const cookies = Cookies.get();
  const userAgent = navigator.userAgent;

  useEffect(() => {
    (async () => {
      let selectedOfferIds = offerIds;
      if (!offerIds) selectedOfferIds = await api.getDefaultOfferId();
      if (!email) window.location.replace("https://pianoinaflash.com/buy-now");

      let { existingUserId } = await api.isUserRegistered(email);

      let password = uuid.v4().toString();
      if (!existingUserId) {
        const { data, error } = await supabase.auth.signUp({
          email: email,
          password: password,
        });

        existingUserId = data.user?.id;
      }

      const response = await api.registerForIntroductoryOffers(
        clientSideId.toString(),
        cookies,
        email.toLowerCase(),
        password.toString(),
        transactionURL,
        userAgent,
        name,
        selectedOfferIds,
        existingUserId
      );

      if (response.ok) {
        const json = await response.json();
        if (json.redirectUrl) {
          window.location = json.redirectUrl;
        } else {
          navigate("/login-form");
        }
      } else {
        navigate("/login-form");
      }
    })();
  }, []);

  return null;
}
